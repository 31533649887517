import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import socket from './src/utils/socket';
import 'react-toastify/dist/ReactToastify.css';
import useAuthState from './src/stores/auth';

// OneSignal SDK initialization
const initializeOneSignal = () => {
  if (typeof window !== 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function() {
        window.OneSignal.init({
          appId: 'b669d6d5-4806-4fd0-9b52-5d58d57fb710', 
          notifyButton: {
            enable: true,
          },
          welcomeNotification: {
            title: 'Welcome!',
            message: 'Thanks for subscribing to notifications.',
          },
        });
      });
    };
  }
};

const RootElementWrapper = ({ element }) => {
  const { user, isLoggedIn } = useAuthState();

  useEffect(() => {
    // Initialize OneSignal
    initializeOneSignal();

    if (isLoggedIn()) {
      socket.emit('userConnect', user._id);

      socket.on('connectionSuccess', () => {
        toast.success('Connected');
      });

      socket.on('orderCancel', productName => {
        toast.success(`Your order of ${productName} has been cancelled.`);
      });

      socket.on('orderReceived', productName => {
        productName
          ? toast.success(`Your order of ${productName} has been received.`)
          : toast.success('Your order has been received.');
      });

      socket.on('orderConfirmed', productName => {
        toast.success(`Your order of ${productName} has been confirmed.`);
      });

      socket.on('orderQuoted', productName => {
        toast.success(`Your order of ${productName} has been quoted.`);
      });

      socket.on('prescriptionReceived', () => {
        toast.success('Your prescription has been received.');
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [isLoggedIn, user]);

  return element;
};

export const wrapRootElement = ({ element }) => (
  <>
    <ToastContainer />
    <RootElementWrapper element={element} />
  </>
);
